import BreadCrumbsHOC from '@components/UI/breadcrumbs';
import useWidget from '@pages/AdditionalServices/context';
import { TabsItemProps } from '@pages/AdditionalServices/context/types';
import AdditionalServiceFilter from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceFilter';
import AdditionalServiceIsVariantListing from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceIsVariantListing';
import SimpleAdditionalServiceListing from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceListing/components/SimpleAdditionalServiceListing';
import AdditionalServiceVariantsTabs from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceVariantsTabs';
import SummaryAdditionalServices from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/SummaryAdditionalServices';
import {
  AdditionalServiceIsVariantContainer,
  AdditionalServiceSimpleContainer,
} from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/StyledComponents';
import React from 'react';

interface Props {
  service: TabsItemProps;
}

const AdditionalServiceContainer = ({ service }: Props) => {
  const { useVariants, useReadyConstructor, useLastDirectory } = useWidget();
  const [variants] = useVariants().useState();
  const [lastDirectory] = useLastDirectory().useState();
  const [ready] = useReadyConstructor().useState();

  if (!ready) {
    return <></>;
  }

  if (lastDirectory.template && lastDirectory.template === '2') {
    return (
      <AdditionalServiceIsVariantContainer>
        {variants.length !== 0 && <AdditionalServiceVariantsTabs />}
        <AdditionalServiceIsVariantListing />
        <SummaryAdditionalServices />
      </AdditionalServiceIsVariantContainer>
    );
  }

  const breadCrumbs = [
    {
      id: 1,
      name: 'Главная',
    },
    {
      id: 2,
      name: service.name,
    },
  ];

  return (
    <AdditionalServiceIsVariantContainer>
      <BreadCrumbsHOC crumbs={breadCrumbs} />
      {variants.length !== 0 && <AdditionalServiceVariantsTabs />}
      <AdditionalServiceSimpleContainer>
        <AdditionalServiceFilter />
        <SimpleAdditionalServiceListing />
      </AdditionalServiceSimpleContainer>
      <SummaryAdditionalServices />
    </AdditionalServiceIsVariantContainer>
  );
};

export default AdditionalServiceContainer;
