import { GridColDef } from '@mui/x-data-grid';

import ServicesName from '../../ServicesName';
import ConversePaymentField from './ConversePaymentField';
import CostField from './CostField';
import CountField from './CountField';
import SumConversePaymentField from './SumConversePaymentField';
import SumField from './SumField';
import SumTaxField from './SumTaxField';
import TaxField from './TaxField';
import TotalField from './TotalField';
import UnitField from './UnitField';

export const getTemplate4Columns = () => {
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: '',
      headerAlign: 'center',
      minWidth: 200,
      renderCell: ServicesName,
    },
    {
      field: 'cost',
      headerName: 'Стоимость',
      headerAlign: 'center',
      minWidth: 220,
      renderCell: CostField,
    },
    {
      field: 'count',
      headerName: 'Кол-во',
      headerAlign: 'center',
      minWidth: 100,
      renderCell: CountField,
    },
    {
      field: 'unit',
      headerName: 'Единица измерения',
      headerAlign: 'center',
      minWidth: 160,
      renderCell: UnitField,
    },
    {
      field: 'sum',
      headerName: 'Сумма',
      headerAlign: 'center',
      width: 220,
      renderCell: SumField,
    },
    {
      field: 'course',
      headerName: 'Курс',
      headerAlign: 'center',
      width: 100,
      align: 'center',
    },
    {
      field: 'conversePayment',
      headerName: 'Конверс. издержки',
      headerAlign: 'center',
      width: 220,
      renderCell: ConversePaymentField,
    },
    {
      field: 'sumConversePayment',
      headerName: 'Сумма в валюте платежа с учетом конверс. изд.',
      headerAlign: 'center',
      width: 220,
      renderCell: SumConversePaymentField,
    },
    {
      field: 'taxRate',
      headerName: 'Ставка налога',
      headerAlign: 'center',
      minWidth: 120,
      renderCell: TaxField,
    },
    {
      field: 'sumTax',
      headerName: 'Сумма налога',
      headerAlign: 'center',
      flex: 1,
      minWidth: 120,
      renderCell: SumTaxField,
    },
    {
      field: 'total',
      headerName: 'Итого',
      headerAlign: 'center',
      flex: 1,
      minWidth: 120,
      renderCell: TotalField,
    },
  ];

  return columns;
};
