import { ServicesProps } from '@services/requests/servicesService/servicesServicesService/interface';
import { BasketAdditionalService } from '@pages/AdditionalServices/context/types';
import { getColumnsForAdditionalService } from './DataForTableGenerator';
import { getRowsForTemplate4 } from './RowGeneratorTemplate4';

export const getChildrenGridDataTemplate4 = (
  id: string,
  service: ServicesProps,
  basket: BasketAdditionalService,
  template: string
) => {
  const rows = getRowsForTemplate4(service, basket[id]);
  const columns = getColumnsForAdditionalService(service, template);

  return [{ rows, columns }];
};
