import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import FieldCellContainer from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/components/AdditionalServicesTable/components/StyledComponents';
import React from 'react';

const UnitField = (props: GridRenderCellParams<any, any, any>) => {
  const { row } = props;
  const fieldData = (Object.values(row).filter((value) => typeof value === 'object') as any[]).find(
    (prop) => prop.code?.includes('countTemp')
  );

  const helper = fieldData && fieldData.hasOwnProperty('helper') && fieldData.helper;
  const name = fieldData && fieldData.hasOwnProperty('name') ? fieldData.name : '';

  return (
    <FieldCellContainer>
      <span>{name}</span>{' '}
      {helper && (
        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: helper }} />}>
          <IconButton sx={{ width: 16, height: 16, backgroundColor: 'grey.100' }}>
            <QuestionMarkIcon sx={{ fontSize: 8 }} />
          </IconButton>
        </Tooltip>
      )}
    </FieldCellContainer>
  );
};

export default UnitField;
