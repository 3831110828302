import CellModesModel from '@components/UI/DefaultTable/helpers';
import {
  DataGrid,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridRow,
  GridRowHeightParams,
} from '@mui/x-data-grid';
import useWidget from '@pages/AdditionalServices/context';
import RowContainer, {
  TableContainer,
} from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/components/AdditionalServicesTable/components/AdditionalServicesTableBody/StyledComponents';
import ServicesName from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/components/AdditionalServicesTable/components/ServicesName';
import React, { FC, MouseEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getPlaceholderByTemplate } from './helper';

export type AdditionalServicesTableBodyProps = {
  rows: any[];
  columns: GridColDef[];
};

const AdditionalRow = (props: any) => {
  const { t } = useTranslation('AdditionalService');
  const { type, isCalculationOnRequest, isIncludedInTheBasicService, template } = props.row;
  const TemplatePlaceholder = getPlaceholderByTemplate(+template);

  if (type === 'header') {
    return (
      <RowContainer style={{ minHeight: '40px' }} className={'MuiDataGrid-row'}>
        <div className={'cell'} style={{ minWidth: props.renderedColumns[0].minWidth }}></div>
        <div className={'cell'} style={{ minWidth: props.renderedColumns[1].minWidth }}></div>
        <div
          className={'cell'}
          style={{ minWidth: props.renderedColumns[2].minWidth, flex: 1 }}
        ></div>

        <div
          className={'cell no-border'}
          style={{ minWidth: props.renderedColumns[3].minWidth, flex: 1 }}
        ></div>
        <div
          className={'cell no-border'}
          style={{ minWidth: props.renderedColumns[4].minWidth, flex: 1 }}
        >
          <span className="cell-text">По тарифам банка</span>
        </div>
        <div
          className={'cell'}
          style={{ minWidth: props.renderedColumns[5].minWidth, flex: 1 }}
        ></div>
        <div className={'cell'} style={{ minWidth: props.renderedColumns[6].minWidth, flex: 1 }}>
          {props.row.taxAmount}%
        </div>
        <div
          className={'cell'}
          style={{ minWidth: props.renderedColumns[7].minWidth, flex: 1 }}
        ></div>
        <div
          className={'cell'}
          style={{ minWidth: props.renderedColumns[8].minWidth, flex: 1 }}
        ></div>
        <div
          className={'cell'}
          style={{ minWidth: props.renderedColumns[9].minWidth, flex: 1 }}
        ></div>
        <div
          className={'cell'}
          style={{ minWidth: props.renderedColumns[10].minWidth, flex: 1 }}
        ></div>
      </RowContainer>
    );
  }
  if (props.row.calculationAccordingToApplication) {
    return (
      <RowContainer className={'MuiDataGrid-row'}>
        <div className={'cell'} style={{ width: props.renderedColumns[0].minWidth }}>
          <ServicesName {...props} />
        </div>
        <div className={'cell'} style={{ flex: 1 }}>
          {t('AdditionalService:CalculationAccordingApplication')}
        </div>
      </RowContainer>
    );
  }
  if (isCalculationOnRequest || isIncludedInTheBasicService) {
    return <TemplatePlaceholder {...props} />;
  }

  //@ts-ignore
  return <GridRow {...props} />;
};

const AdditionalServicesTableBody: FC<AdditionalServicesTableBodyProps> = ({ rows, columns }) => {
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
  const handleCellClick = useCallback((params: GridCellParams, event: MouseEvent) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (!event.currentTarget.contains(event.target as Element)) {
      return;
    }

    setCellModesModel((prevModel) => CellModesModel(prevModel, params));
  }, []);

  const handleCellModesModelChange = useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  const isNotRegularRow = (params: GridRowHeightParams) =>
    ['result', 'header'].includes(params.model.type);

  return (
    <TableContainer>
      <DataGrid
        rows={rows}
        columns={columns}
        hideFooter
        slots={{ row: AdditionalRow }}
        disableColumnMenu
        disableRowSelectionOnClick
        disableDensitySelector
        disableColumnSelector
        showCellVerticalBorder
        disableVirtualization
        disableColumnFilter
        autoHeight
        showColumnVerticalBorder
        getRowHeight={(params) => {
          if (isNotRegularRow(params)) {
            return 40;
          }
          return 'auto';
        }}
        sx={{
          border: '1px solid #dbe0ec',
          borderTop: 0,
          borderRadius: '0 0 20px 20px',
          '& .MuiDataGrid-cell': {
            borderColor: '#dbe0ec',
          },
          '& .MuiDataGrid-columnHeader': {
            borderColor: '#dbe0ec',
          },
          '& .MuiDataGrid-row': {
            borderBottomColor: '#dbe0ec',
          },
          '& .MuiDataGrid-withBorderColor': {
            borderBottomColor: '#dbe0ec',
          },
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            position: 'static',
          },
          '& .MuiDataGrid-virtualScrollerContent': {
            height: 'auto !important',
          },
        }}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
      />
    </TableContainer>
  );
};

export default AdditionalServicesTableBody;
