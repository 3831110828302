import { GridColDef } from '@mui/x-data-grid';
import FieldItem from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/components/AdditionalServicesTable/components/FieldItem';
import {
  ServicesProps,
  ServicesServicePriceCalculationMutationProps,
} from '@services/requests/servicesService/servicesServicesService/interface';

import { getTemplate3Columns } from './Templates/Template3/Template3Columns';
import { getTemplate4Columns } from './Templates/Template4/Template4Columns';
import { getTemplate5Columns } from './Templates/Template5/Template5Columns';

export const getColumnsForAdditionalService = (s: ServicesProps, template: string) => {
  const fieldColumns: GridColDef[] = s.fields
    .filter((f) => f.type !== 'hidden')
    .map((f) => ({
      field: f.code,
      headerName: f.name,
      headerAlign: 'center',
      minWidth: 200,
      renderCell: FieldItem,
    }));

  switch (template) {
    case '1':
    case '4':
      return getTemplate4Columns();
    case '3':
      return getTemplate3Columns(fieldColumns);
    case '5':
      return getTemplate5Columns(fieldColumns);
  }
};

export const getRowsForAdditionalService = (
  s: ServicesProps,
  serviceData?: ServicesServicePriceCalculationMutationProps
) => {
  let item = {
    id: s.id,
    name: s.name,
    description: s.description,
    localizedDescriptions: s.localized_descriptions_data,
    cost: 0,
    count: 1,
    sum: 0,
    course: 0,
    conversePayment: 0,
    sumConversePayment: 0,
    taxRate: 0,
    sumTax: 0,
    total: 0,
    calculationAccordingToApplication: true,
    isCalculationOnRequest: false,
    isIncludedInTheBasicService: false,
    convertedCost: 0,
    currencyTransfer: 0,
    exchangeControl: 0,
	template: s.template
  };

  if (serviceData?.result?.result) {
    const {
      basePrice,
      fullPriceInTargetCurrency,
      taxInTargetCurrency,
      innerCurrency,
      priceInTargetCurrency,
      conversionFee,
    } = serviceData.result.result;

    item = {
      ...item,
      course: +innerCurrency,
      cost: basePrice,
      total: fullPriceInTargetCurrency,
      sumTax: taxInTargetCurrency,
      conversePayment: conversionFee,
      sumConversePayment: basePrice + conversionFee,
      calculationAccordingToApplication: basePrice < 0,
      isCalculationOnRequest: basePrice === -1,
      isIncludedInTheBasicService: basePrice === 0,
      convertedCost: priceInTargetCurrency,
    };
  }

  const temp = s.fields
    .filter((f) => f.type !== 'hidden')
    .reduce((acc, f) => {
      acc = {
        ...acc,
        [f.code]: f,
      };
      return acc;
    }, item);

  return [temp];
};
