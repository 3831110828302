import TextField from '@mui/material/TextField';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import useWidget from '@pages/AdditionalServices/context';
import { FieldState } from '@pages/AdditionalServices/context/types';
import { SetterContext, StateUpdater } from '@settings/core/stateContexts/types';
import React, { CSSProperties, useState } from 'react';

const style: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'center',
  textAlign: 'center',
};

const CountField = (props: GridRenderCellParams<any, any, any>) => {
  const { row } = props;
  const { useFieldStateServices, actions } = useWidget();
  const { recalculateService } = actions;
  const [states, setStates] = useFieldStateServices().useState();

  const countField = (
    Object.values(row).filter((value) => typeof value === 'object') as any[]
  ).find((prop) => prop.code?.includes('countTemp'));
  const [countKey, countValue] =
    Object.entries(states[row.id]).find(([key]) => key.includes('countTemp')) ?? [];

  const [value, setValue] = useState<string>(countValue?.value.toString());

  const setState = (value: SetterContext<FieldState>) => {
    if (typeof value !== 'function') {
      setStates((prevState) => ({
        ...prevState,
        [row.id]: value,
      }));

      recalculateService(String(row.id));
      return;
    }

    const stateUpdater = value as StateUpdater<FieldState>;
    setStates((prevState) => {
      return {
        ...prevState,
        [row.id]: stateUpdater(states[row.id]),
      };
    });

    recalculateService(String(row.id));
  };

  const onChangeCount = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;

    if (!countKey) {
      return;
    }

    if (value === '0' || value === '') {
      setValue('0');
      setState((prevState: FieldState) => ({
        ...prevState,
        [countKey]: {
          ...prevState[countKey],
          value: '0',
        },
      }));
      return;
    }

    setValue(value);
    setState((prevState: FieldState) => {
      return {
        ...prevState,
        [countKey]: {
          ...prevState[countKey],
          value: value,
        },
      };
    });
  };

  const settings =
    countField && countField.hasOwnProperty('settings') ? JSON.parse(countField.settings) : false;

  if (settings && settings.editable) {
    return (
      <div style={style}>
        <TextField
          fullWidth
          size={'small'}
          InputLabelProps={{ shrink: true }}
          type={'number'}
          label={''}
          variant="outlined"
          value={value}
          onChange={onChangeCount}
          sx={{ '.MuiInputBase-input': { textAlign: 'center' } }}
        />
      </div>
    );
  }

  return <div style={style}>{value}</div>;
};

export default CountField;
