import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useWidget from '@pages/AdditionalServices/context';
import { ContractorsOptions, FieldState } from '@pages/AdditionalServices/context/types';
import AdditionalServicesTableHeaderContainer from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/components/AdditionalServicesTable/components/AdditionalServicesTableHeader/StyledComponents';
import { PriceWithTaxItemContainer } from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceListing/components/SimpleAdditionalServiceListing/components/StyledComponents';
import FieldsMap from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/FieldsMap';
import { CurrencyData } from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';
import { ServicesFieldProps } from '@services/requests/servicesService/interface';
import {
  ServicesProps,
  ServicesServicePriceCalculationMutationProps,
} from '@services/requests/servicesService/servicesServicesService/interface';
import { TaxData } from '@services/requests/taxLoader/TaxLoaderQuery';
import { SetterContext } from '@settings/core/stateContexts/types';
import React, { Dispatch, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import MButton from '../../../../../../../../../../../../assets/mui/@material-extend/MButton';

interface PriceWithTaxItemProps {
  value: number;
  taxValue: number;
  currency: string;
  type?: 'table' | 'list';
}

interface Props {
  state: FieldState;
  entity: ServicesServicePriceCalculationMutationProps | null;
  isOpen: boolean;
  setIsOpen: Dispatch<boolean>;
  contractorsOptions: ContractorsOptions;
  taxes: TaxData[];
  fields: ServicesFieldProps[];
  currentFields: ServicesFieldProps[];
  currencies: CurrencyData[];
  service: ServicesProps;
  totalServiceCost: number;
  totalSeriveTax: number;
  template: string;

  setState(value: SetterContext<FieldState>): void;
}

const defaultResult = {
  calculationAccordingToApplication: true,
  basePrice: 1,
  taxInTargetCurrency: 1,
  base_tax: 1,
  converted_price: { amount: 1, conversion_fee: 1 },
  converted_price_with_tax: { amount: 1, conversion_fee: 1 },
  converted_tax: { amount: 1, conversion_fee: 1 },
  course: 1,
};

interface TextItemProps {
  value?: number;
  currency?: string;
  title?: string;
}

const PriceWithTaxItem = ({ value, taxValue, currency }: PriceWithTaxItemProps) => {
  return (
    <PriceWithTaxItemContainer className={'--center'}>
      <Typography variant={'subtitle1'}>
        {value.toFixed(2)} {currency}
      </Typography>
      <Typography variant={'caption'} color={'#707070'} fontWeight={'bold'}>
        в т.ч. налог {taxValue.toFixed(2)} {currency}
      </Typography>
    </PriceWithTaxItemContainer>
  );
};

const ValueEmpty = ({ title }: TextItemProps) => {
  return (
    <PriceWithTaxItemContainer>
      <Typography variant={'subtitle1'}>{title}</Typography>
    </PriceWithTaxItemContainer>
  );
};

const AdditionalServicesTableHeader: FC<Props> = (props) => {
  const { service, entity, currentFields, totalSeriveTax, totalServiceCost, state, template } =
    props;
  const { t } = useTranslation(['AdditionalService', 'Actions']);

  const { description, name, id } = service;

  let {
    calculationAccordingToApplication = false,
    basePrice = 1,
    taxInTargetCurrency = 1,
  } = entity?.result?.result || defaultResult;
  const [selectedItems, setSelectedItems] = useWidget().useSelectedSubAddServices().useState([id]);
  const [basket] = useWidget().useBasketAdditionalService().useState([id]);
  const [{ currencies }] = useWidget().useAdditionalInfo().useState();
  const [isStateChanged, setIsStateChanged] = useState<boolean>(false);
  const { recalculateServices } = useWidget().actions;

  const isNotRegularTemplate = !['2'].includes(template);
  const isPaymentService = template === '3';

  const serviceName = isPaymentService ? 'Платежи' : name;
  const serviceDescription = description && description !== '' ? description: serviceName;

  if (totalServiceCost > 0 || totalSeriveTax > 0) {
    basePrice = totalServiceCost;
    taxInTargetCurrency = totalSeriveTax;
  }

  useEffect(() => {
    if (currentFields.length === 0 || isNotRegularTemplate) {
      onClick();
    }
  }, []);

  useEffect(() => {
    setIsStateChanged(!isNotRegularTemplate);
  }, [state]);

  const onClick = () => {
    const childrenIds = service.childs.map((i) => i.id);

    recalculateServices([id, ...childrenIds]);
    const childServices = service.childs.reduce((acc, cId) => {
      acc = {
        ...acc,
        [cId.id]: cId,
      };
      return acc;
    }, {});
    setSelectedItems((prev) => {
      const checked = prev[id] || false;

      if (!checked || isStateChanged) {
        return {
          ...prev,
          [id]: service,
          ...childServices,
        };
      }

      return {
        ...Object.keys(prev).reduce((acc, k) => {
          if (k !== id && !childrenIds.includes(k)) {
            acc = {
              ...acc,
              [k]: prev[k],
            };
          }
          return acc;
        }, {}),
      };
    });
    setIsStateChanged(false);
  };

  const inOrder = selectedItems[id];
  const result = basket[id];

  const currentCurrency = currencies.find((c) => Number(result?.currencyId) === Number(c.id));

  return (
    <AdditionalServicesTableHeaderContainer>
      <div className="header">
        <div className="leftPath">
          <div className="name">
            <Tooltip
              title={<div dangerouslySetInnerHTML={{ __html: serviceDescription }} />}
              placement={'top'}
            >
              <IconButton sx={{ width: 16, height: 16, backgroundColor: 'grey.100' }}>
                <QuestionMarkIcon sx={{ fontSize: 8 }} />
              </IconButton>
            </Tooltip>
            <Typography variant={'body1'} letterSpacing={'0.32px'} maxWidth={600}>
              {serviceName}
            </Typography>
          </div>
          <div className="fields">
            {!isNotRegularTemplate && <FieldsMap realTimeValidation={true} {...props} />}
          </div>
        </div>
        <div className="rightPath">
          {basePrice < 0 && <ValueEmpty title={t('AdditionalService:OnRequest')} />}
          {(!inOrder || isStateChanged) && basePrice > 0 && (
            <MButton
              onClick={onClick}
              className={'add-to-card-action'}
              variant={'outlined'}
              color={'secondary'}
              fullWidth={true}
            >
              {t('Actions:Calculate')}
            </MButton>
          )}
          {inOrder && basePrice > 0 && !isPaymentService && (
            <>
              {calculationAccordingToApplication && (
                <ValueEmpty title={t('AdditionalService:CalculationAccordingApplication')} />
              )}
              {!calculationAccordingToApplication && (
                <PriceWithTaxItem
                  taxValue={taxInTargetCurrency}
                  value={basePrice}
                  currency={currentCurrency?.code || ''}
                />
              )}
            </>
          )}
        </div>
      </div>
    </AdditionalServicesTableHeaderContainer>
  );
};

export default AdditionalServicesTableHeader;
